<template>
  <div class="m_electronic-invoice">
    <m-title title="电子发票" />
    <div class="electronic-tip">
      <div class="electronic-icon">
        <svg-icon icon="icon-tishi" />
      </div>
      <div class="electronic-body">
        电子发票目前将在3～5个工作日内在本系统生成，请耐心等待。
        财务咨询电话：朱老师
        <a href="tel:028-85531022">028-85531022</a>;
        <a href="tel:18048505102">18048505102</a>; 咨询时间:周一至周五
        9:00-18:00
      </div>
    </div>
    <div class="electronic-list-body">
      <div class="electronic-list" v-if="invoiceList.length > 0">
        <div
          class="electronic-item"
          v-for="(item, index) in invoiceList"
          :key="index"
        >
          <div class="electronic-left">
            <div class="electronic-title">
              <span><label>年份:</label>{{ item.annual }}</span>
              <span><label>发票号:</label>{{ item.invoiceNo }}</span>
            </div>
            <div class="electronic-time">
              <label>开票日期:</label>{{ item.openTime }}
            </div>
          </div>
          <div class="electronic-right">
            <div class="button" @click="downPDFClick(item)">下载</div>
          </div>
        </div>
      </div>
      <van-empty v-else :image="customEmptyImg" description="暂无发票" />
    </div>
  </div>
</template>
<script>
import MTitle from "@/components/m_title.vue";
import SvgIcon from "@/components/svgIcon.vue";
import { singUpInfoInvoiceList } from "@/api/registerController";

export default {
  name: "m_myCourse",
  components: { SvgIcon, MTitle },
  data() {
    return {
      customEmptyImg: require("@/assets/images/custom-empty-image.png"),
      invoiceList: [],
    };
  },
  created() {
    this.getElectronicInvoice();
  },
  methods: {
    getElectronicInvoice() {
      singUpInfoInvoiceList().then((res) => {
        this.invoiceList = res.data.InvoiceList;
      });
    },
    downPDFClick(data) {
      if (data.invoiceUrl) {
        const link = document.createElement("a");
        const item = JSON.parse(data.invoiceUrl);
        link.href = item[0].url;
        link.download = item[0].name;
        link.click();
      } else {
        this.$notify({ message: `未找到发票`, type: "warning" });
      }
    },
  },
};
</script>
<style scoped lang="scss">
.m_electronic-invoice {
  height: 100%;
  width: 100%;
  .electronic-tip {
    margin-top: 10px;
    display: flex;
    align-items: flex-start;
    border-radius: 8px;
    padding: 8px 12px;
    background: #ffffff;
    font-size: 12px;
    color: #e56500;
    a {
      color: #419efb;
    }
    .electronic-body {
      margin-left: 5px;
    }
  }
  .electronic-list-body {
    margin-top: 10px;
    border-radius: 8px;
    padding: 8px 12px;
    background: #ffffff;
    .electronic-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 12px;
      .electronic-left {
        width: calc(100% - 54px);
        .electronic-title {
          font-size: 14px;
          margin-bottom: 2px;
          color: #3d3d3d;
          span {
            margin-right: 10px;
            label {
              font-weight: bold;
            }
          }
        }
        .electronic-time {
          font-size: 12px;
          color: #818496;
        }
      }
      .electronic-right {
        width: 54px;
        .button {
          background: rgba(181, 4, 19, 0.1);
          padding: 3px 15px;
          width: max-content;
          font-size: 12px;
          border-radius: 240px;
          color: #b50413;
        }
      }
    }
  }
}
::v-deep .van-empty__image {
  width: 136px;
  height: 103px;
}
</style>
